import { getAnalytics, logEvent as logEventBase } from "firebase/analytics";
import { firebase } from "../firebase/init";

const analytics = getAnalytics(firebase);

export const logEvent = (
  eventName: string,
  eventParams?: Record<string, any>
) => {
  logEventBase(analytics, eventName, eventParams);
};
