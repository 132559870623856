// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOFggB00yi9rtmOO7GA00fPIUlzhs1Fi8",
  authDomain: "dukes-group.firebaseapp.com",
  projectId: "dukes-group",
  storageBucket: "dukes-group.appspot.com",
  messagingSenderId: "325220876086",
  appId: "1:325220876086:web:b2d71326ef47d1967fbd97",
  measurementId: "G-FL0G1HPSDG"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);