// src/App.js

import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ProductPage } from "./ProductPage";
import { ProductRangePage } from "./ProductRangePage";
import { ProductsPage } from "./ProductsPage";
import { getProduct, getRange } from "./store/state";
import { RetailersStandalonePage } from "../retailers/retailers";
import { LandingPage } from "../landing/LandingPage";
import { ContactUsPage } from "../contact/ContactUs";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { logEvent } from "../analytics/init";
import LogoImage from "../assets/logo-transparent.webp";
import { SEO } from "../components/SEO";

const router = createBrowserRouter([
  {
    path: "/products",
    element: <RouterOutlet outlet={<ProductsPage />} />,
  },
  {
    path: "/products/:productRange",
    element: <RouterOutlet outlet={<ProductRangePage />} />,
    loader: productRangeLoader,
  },
  {
    path: "/products/:productRange/:productColourCode",
    element: <RouterOutlet outlet={<ProductPage />} />,
    loader: productPageLoader,
  },
  {
    path: "/contact",
    element: <RouterOutlet outlet={<ContactUsPage />} />,
  },
  {
    path: "/retailers",
    element: <RouterOutlet outlet={<RetailersStandalonePage />} />,
  },

  {
    path: "/",
    element: <RouterOutlet outlet={<LandingPage />} />,
  },
  // redirect others to products
  {
    path: "*",
    element: <RouterOutlet outlet={<Navigate to="/" replace />} />,
  },
  // Other routes
]);

const description = `Dukes Group offers premium engineered timber flooring across the United States. Our high-quality, sustainable products come in various sizes and grades, combining timeless elegance with natural grains for visual perfection. Engineered timber flooring provides superior stability, cost-effectiveness, and durability over traditional solid timber flooring. Meticulously crafted with advanced techniques, our flooring ensures lasting performance and sophisticated aesthetics. Trust Dukes Group for exceptional expertise and professionalism in the timber flooring industry.`;

function App() {
  return (
    <>
      <SEO
        title="Engineered Timber Flooring"
        description={description}
        image={LogoImage}
        url="https://dukesgroup.us"
        keywords="Premium Engineered Timber Flooring, Wholesale Timber Flooring, High-Quality Timber Flooring, Sustainable Flooring, Durable Engineered Flooring, Timber Flooring USA, Natural Grain Timber Flooring, Stable Timber Flooring, Cost-Effective Flooring, Professional Flooring Services, Dukes Group Timber Flooring, Sophisticated Timber Flooring, Aesthetic Timber Flooring, Advanced Timber Construction, Long-Lasting Timber Floors, Quality Timber Planks, Engineered Wood Flooring, Timber Flooring Expertise."
      >
        <meta charSet="utf-8" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://dukesgroup.us" />
      </SEO>
      <RouterProvider router={router} />
      <ToastContainer theme="dark" hideProgressBar />
    </>
  );
}

function RouterOutlet({ outlet }: { outlet: React.ReactNode }) {
  useRouterAnalytics();
  return <>{outlet}</>;
}

function RouterHooks() {
  useRouterAnalytics();
  return null;
}

function useRouterAnalytics() {
  let location = useLocation();

  React.useEffect(() => {
    // Google Analytics
    setTimeout(() => {
      logEvent("pageview", {
        page_path: location.pathname,
        page_title: document.title,
      });
      // Slight delay so that the doc title hook runs first...
    }, 100);
  }, [location]);
}

export default App;

function productRangeLoader({ params }: { params: { productRange: string } }) {
  return { range: getRange(params.productRange) };
}

function productPageLoader({
  params,
}: {
  params: { productRange: string; productColourCode: string; };
}) {
  return {
    product: getProduct(params.productRange, params.productColourCode),
    range: getRange(params.productRange),
  };
}
