import React from "react";
import styled from "styled-components";

import BackgroundImage from "../assets/vid-still.webp";
import Video from "../assets/bg-vid.mp4";
import VideoMobile from "../assets/bg-mob-vid.mp4";
import { useShouldHideOnMobile } from "./constants";

export function FlyingTrees({
  children,
  height,
}: {
  children: React.ReactNode;
  height?: string;
}) {
  const isMobile = useShouldHideOnMobile();

  return (
    <Background height={height}>
      <StyledVideo autoPlay loop muted src={isMobile ? VideoMobile : Video} />

      {children}
    </Background>
  );
}

//vid-still.webp
const Background = styled.div<{ height?: string }>`
  background-color: #343434;
  height: ${(props) => props.height || "100vh"};
  width: 100vw;

  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
`;
