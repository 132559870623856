import React, { useCallback, useEffect, useState } from "react";
import { StyledInput } from "../components/input";
import { getCookieValue } from "./cookies";
import { salesInClient } from "./salesinClient";
import {
  LoadingButtonWithSpinner,
  StyledLoginButton,
  InfoMessageContainer,
  ErrorMesssageContainer,
} from "../components";


export function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loggingIn, setLoggingIn] = useState(false);

  const [loginError, setLoginError] = useState<string | null>(null);

  const onLogin = useCallback(async () => {
    setLoggingIn(true);
    const salesClientResponse = await salesInClient.login(
      username,
      password,
      true
    );

    if (
      !salesClientResponse.shouldRedirect &&
      salesClientResponse.errorMessage
    ) {
      setLoginError(salesClientResponse.errorMessage);
      setLoggingIn(false);
      return;
    }

    if (salesClientResponse.shouldRedirect) {
      window.location.href = salesClientResponse.redirectUrl;
      return;
    }
    setLoggingIn(false);
  }, [username, password]);

  useEffect(() => {
    const storedUsername = getCookieValue("JobSync_Login", "username");
    if (typeof storedUsername === "string") {
      setUsername(storedUsername);
    }
  }, []);

  const LoginButton = loggingIn ? LoadingButtonWithSpinner : StyledLoginButton;

  return (
    <>
      <StyledInput
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={loggingIn}
      />
      <StyledInput
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={loggingIn}
      />
      <InfoMessageContainer>
        {loggingIn ? "Loading the portal..." : ""}
      </InfoMessageContainer>
      {loginError && (
        <ErrorMesssageContainer>{`⚠️ ${loginError}`}</ErrorMesssageContainer>
      )}
      <LoginButton
        onClick={onLogin}
        disabled={!username || !password || loggingIn}
      >
        {loggingIn ? "" : "Login"}
      </LoginButton>
    </>
  );
}
