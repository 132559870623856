/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
import '../../environment/dev.js';
import { createHtmlInternal, unwrapHtml, } from '../../internals/html_impl.js';
/** Safely parses a string using the HTML parser. */
export function parseHtml(parser, html) {
    return parseFromString(parser, html, 'text/html');
}
/**
 * Safely parses a string using the XML parser. If the XML document is found to
 * contain any elements from the HTML or SVG namespaces, an error is thrown for
 * security reasons.
 */
export function parseXml(parser, xml) {
    const doc = parseFromString(parser, createHtmlInternal(xml), 'text/xml');
    const iterator = document.createNodeIterator(doc, NodeFilter.SHOW_ALL);
    let currentNode;
    while ((currentNode = iterator.nextNode())) {
        if (currentNode instanceof HTMLElement ||
            currentNode instanceof SVGElement) {
            let message = 'unsafe XML';
            if (process.env.NODE_ENV !== 'production') {
                message = `attempted to parse an XML document that embeds HTML or SVG content`;
            }
            throw new Error(message);
        }
    }
    return doc;
}
/** Safely parses a string using the HTML or XML parser. */
export function parseFromString(parser, content, contentType) {
    return parser.parseFromString(unwrapHtml(content), contentType);
}
