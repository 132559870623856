import styled from "styled-components";
import LogoImage from "../assets/logo-transparent.webp";
import React from "react";

type LogoProps = {
  width: number;
};
export function Logo({ width = 120 }: LogoProps) {
  return (
    <Container size={width}>
      <img src={LogoImage} alt="logo" width={width} />
    </Container>
  );
}

const Container = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
