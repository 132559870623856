import { logEvent } from "../analytics/init";
import { b64_md5 } from "./base64";
import { setCookieValue } from "./cookies";

export type LoginResponse =
  | {
      shouldRedirect: true;
      redirectUrl: string;
    }
  | {
      shouldRedirect: false;
      errorMessage: string;
    };

class SalesInClient {
  private currentJobSyncSite: string;
  private currentB2BSite: string;
  private currentAuthSite: string;
  private companyCode: string;

  constructor(
    currentJobSyncSite: string,
    currentB2BSite: string,
    currentAuthSite: string,
    companyCode: string
  ) {
    this.currentJobSyncSite = currentJobSyncSite;
    this.currentB2BSite = currentB2BSite;
    this.currentAuthSite = currentAuthSite;
    this.companyCode = companyCode;
  }

  private hashPassword(password: string): string {
    //has with b64_md5
    return b64_md5(password);
  }

  async login(
    username: string,
    password: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    try {
      logEvent("login", { username });

      const hashedPw = this.hashPassword(password);
      const response = await fetch(
        `${this.currentAuthSite}/api/Authenticate/UserLogin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userName: username,
            password: hashedPw + "==",
            companyCode: this.companyCode,
            companyID: 0,
          }),
        }
      );

      if (response.ok) {
        const data = (await response.json()) as any;
        if (data.ResponseCode == 0) {
          logEvent("login_success", { username });

          if (rememberMe) {
            setCookieValue("JobSync_Login", "username", username);
            setCookieValue("JobSync_Login", "remember", rememberMe);
          }
          var currentURL = encodeURIComponent(window.location.href);

          if (data.IsCustomer) {
            if (this.currentJobSyncSite != null) {
              return {
                shouldRedirect: true,
                redirectUrl: `${this.currentB2BSite}/Account/AutoLogin?accesstoken=${data.ResponseMessage}&LoginPage=${currentURL}`,
              };
            } else {
              return {
                shouldRedirect: true,
                redirectUrl: `${this.currentJobSyncSite}/AutoLogin?accesstoken=${data.ResponseMessage}&LoginPage=${currentURL}`,
              };
            }
          } else {
            return {
              shouldRedirect: true,
              redirectUrl: `${this.currentJobSyncSite}/AutoLogin?accesstoken=${data.ResponseMessage}&LoginPage=${currentURL}`,
            };
          }
        } else if (data.ResponseCode == 1) {
          logEvent("login_failed", { username });
          return {
            shouldRedirect: false,
            errorMessage: data.ResponseMessage.startsWith(
              "You have entered invalid credentials too many times"
            )
              ? data.ResponseMessage
              : "The username and password entered, do not match. If you believe this is in error, please contact your account administrator.",
          };
        } else {
          logEvent("login_failed", {
            username,
            response: data.ResponseMessage,
          });

          alert(data.ResponseMessage);
          return {
            shouldRedirect: false,
            errorMessage: data.ResponseMessage,
          };
        }
      } else {
        logEvent("login_failed", {
          username,
          response: response.statusText,
        });
        return {
          shouldRedirect: false,
          errorMessage: "An error occurred while logging in. Please try again.",
        };
      }
    } catch (error) {
      logEvent("login_failed", {
        username,
        response: error,
      });
      return {
        shouldRedirect: false,
        errorMessage: "An error occurred while logging in. Please try again.",
      };
    }
  }

  async sendForgotPasswordEmail(email: string) {
    try {
      var currentURL = encodeURIComponent(window.location.href);

      const url = `${
        this.currentJobSyncSite
      }/WebLogin.asmx/ForgotPassword_JSONP?${new URLSearchParams({
        email: email,
        companyCode: this.companyCode,
        authenticationToken: this.companyCode,
        currentURL: currentURL,
      })}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = (await response.json()) as any;
        if (data.ResponseCode == 0 || data.ResponseCode === 1) {
          return {
            sent: true,
          };
        } else {
          return {
            sent: false,
            errorMessage: data.ResponseMessage,
          };
        }
      }

      return {
        sent: false,
        errorMessage:
          "An error occurred while sending the email. Please try again.",
      };
    } catch (error) {
      return {
        sent: false,
        errorMessage:
          "An error occurred while sending the email. Please try again.",
      };
    }
  }
}

export const salesInClient = new SalesInClient(
  "https://web.salesin.com",
  "https://b2b.salesin.com",
  "https://auth.salesin.com",
  "a6EIW3iEB5Y/4OicaZpzlg==" // companyCode
);
