import React, { useEffect, useMemo } from "react";
import { Content, Layout } from "../components/layout";
import { useLoaderData } from "react-router-dom";
import { ImageType, Product, ProductRange as ProductRangeType } from "./types";
import { ProductRange } from "../components/ProductRange";
import { Heading } from "../components/Heading";
import { ProductCard } from "../components/Cards/ProductCard";
import styled from "styled-components";
import { useSEO } from "../hooks/seo";
import { SEO } from "../components/SEO";
import { productThumbnail } from "./utils";
import { ImageWithPlaceholder } from "../components/ImageWithPlaceholder";
import { ProductImageGallery } from "../components/ProductImageGallery";
import { useMediaQuery } from "react-responsive";
import { ImageCarousel } from "../components/ProductImageCarousel";
import { colours } from "../theme/theme";
import { ExpandedImageModal } from "../components/ExpandedImageModal";

export function ProductPage() {
  const { product, range } = useLoaderData() as {
    product: Product;
    range: ProductRangeType;
  };
  useSEO({ title: `${range.name} ${product.colour}` });
  const [mainImageKey, setMainImageKey] =
    React.useState<ImageType>("room");

  useEffect(() => {
    // If we change products - scroll the product into view
    const productElement = document.getElementById(`product_${product.code}`);
    if (productElement) {
      productElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [product.code]);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Layout
      breadCrumbs={[
        { href: "/products", name: "Products" },
        { href: `/products/${range.code}`, name: range.name },
        {
          href: `/products/${range.code}/${product.code}`,
          name: product.colour,
        },
      ]}
    >
      <SEO
        title={`${product.colour} ${range.name}`}
        description={`${range.name} | ${product.colour}  - ${range.description}`}
      />

      <Content>
        <CentreTitle id={`product_${product.code}`}>
          <Heading level="h1">{range.name}</Heading>
          <Heading level="h2">{product.colour}</Heading>
        </CentreTitle>
        <InfoWrapper>
          {isMobile ? (
            <ImageCarousel product={product} />
          ) : (
            <MainProductImageWithExpand
              product={product}
              selectedImage={mainImageKey}
            />
          )}
          <ProductCardWrapper>
            <ProductCard product={product} range={range} />
          </ProductCardWrapper>
        </InfoWrapper>
        {!isMobile && (
          <ProductImageGalleryWrapper>
            <ProductImageGallery product={product} onClick={setMainImageKey} />
          </ProductImageGalleryWrapper>
        )}

        {!isMobile && <StyledPageBreak />}
        <Heading level="h3">{`Other colors in the ${range.name} range:`}</Heading>
        <ProductRange range={range} />
      </Content>
    </Layout>
  );
}

const MainProductImageWithExpand = ({
  product,
  selectedImage,
}: {
  product: Product;
  selectedImage: ImageType;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const mainImage = useMemo(
    () => productThumbnail(product, selectedImage, "600"),
    [product, selectedImage]
  );


  return (
    <ImageWithExpandWrapper>
      <Image src={mainImage} alt={product.colour} height={600} width={400} />
      <ExpandIcon onClick={() => setIsExpanded(!isExpanded)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M32 32C14.3 32 0 46.3 0 64l0 96c0 17.7 14.3 32 32 32s32-14.3 32-32l0-64 64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7 14.3 32 32 32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0 0-64zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0 0 64c0 17.7 14.3 32 32 32s32-14.3 32-32l0-96c0-17.7-14.3-32-32-32l-96 0zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 64-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0c17.7 0 32-14.3 32-32l0-96z" />
        </svg>
      </ExpandIcon>
      {isExpanded && (
        <ExpandedImageModal
          onClose={() => setIsExpanded(false)}
          selectedImage={selectedImage}
          product={product}
        />
      )}
    </ImageWithExpandWrapper>
  );
};

const ProductCardWrapper = styled.div`
  max-width: 350px;
`;

const CentreTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  flex-direction: column;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    gap: 2rem;
  }
`;
const Image = styled(ImageWithPlaceholder)`
  border-radius: 5px;
  box-shadow: 0 0 7px #000;
  object-fit: cover;
`;

const ImageWithExpandWrapper = styled.div`
  position: relative;
`;

const ExpandIcon = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  svg {
    fill: ${colours.lightGold};
    width: 100%;
    height: 100%;
  }
`;

const ProductImageGalleryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
`;

const StyledPageBreak = styled.hr`
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
  margin-bottom: 2rem;
  // less opacity
  opacity: 0.2;
`;
