import { Product } from "../../types";

import { getProductImageMap as getProductImageMapBase } from "../../utils";

const getProductImageMap = (productKey: string) =>
  getProductImageMapBase(productKey, "inspire");

export const inspireProducts: Product[] = [
  {
    code: "240-15-1",
    colour: "Saddle",
    colourCode: "Saddle",
    order: 1,
    images: getProductImageMap("240-15-1_Saddle"),
  },
  {
    code: "240-15-2",
    colour: "Barley",
    colourCode: "Barley",
    order: 2,
    images: getProductImageMap("240-15-2_Barley"),
  },
  {
    code: "240-15-3",
    colour: "Vanilla",
    colourCode: "Vanilla",
    order: 3,
    images: getProductImageMap("240-15-3_Vanilla"),
  },
  {
    code: "240-15-5",
    colour: "Harvest",
    colourCode: "Harvest",
    order: 4,
    images: getProductImageMap("240-15-5_Harvest"),
  },
  {
    code: "240-15-6",
    colour: "Storm",
    colourCode: "Storm",
    order: 5,
    images: getProductImageMap("240-15-6_Storm"),
  },
].sort((a, b) => a.order - b.order);
