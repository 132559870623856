// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { useEffect } from 'react';
import { bind, bindAll } from 'bind-event-listener';
import noop from '@atlaskit/ds-lib/noop';
import { UNSAFE_useLayering } from '@atlaskit/layering';
export var useCloseManager = function useCloseManager(_ref) {
  var isOpen = _ref.isOpen,
    onClose = _ref.onClose,
    popupRef = _ref.popupRef,
    triggerRef = _ref.triggerRef,
    capture = _ref.shouldUseCaptureOnOutsideClick,
    shouldCloseOnTab = _ref.shouldCloseOnTab;
  var _UNSAFE_useLayering = UNSAFE_useLayering(),
    isLayerDisabled = _UNSAFE_useLayering.isLayerDisabled,
    currentLevel = _UNSAFE_useLayering.currentLevel;
  useEffect(function () {
    if (!isOpen || !popupRef) {
      return noop;
    }
    var closePopup = function closePopup(event) {
      if (onClose) {
        onClose(event);
      }
    };

    // This check is required for cases where components like
    // Select or DDM are placed inside a Popup. A click
    // on a MenuItem or Option would close the Popup, without registering
    // a click on DDM/Select.
    // Users would have to call `onClose` manually to close the Popup in these cases.
    // You can see the bug in action here:
    // https://codesandbox.io/s/atlaskitpopup-default-forked-2eb87?file=/example.tsx:0-1788
    var onClick = function onClick(event) {
      var target = event.target;
      var doesDomNodeExist = document.body.contains(target);
      if (!doesDomNodeExist) {
        return;
      }
      if (isLayerDisabled()) {
        //if it is a disabled layer, we need to disable its click listener.
        return;
      }
      var isClickOnPopup = popupRef && popupRef.contains(target);
      var isClickOnTrigger = triggerRef && triggerRef.contains(target);
      if (!isClickOnPopup && !isClickOnTrigger) {
        closePopup(event);
      }
    };
    var onKeyDown = function onKeyDown(event) {
      if (isLayerDisabled()) {
        return;
      }
      var key = event.key;
      if (key === 'Escape' || key === 'Esc' || shouldCloseOnTab && key === 'Tab') {
        closePopup(event);
      }
    };
    var unbind = bindAll(window, [{
      type: 'click',
      listener: onClick,
      options: {
        capture: capture
      }
    }, {
      type: 'keydown',
      listener: onKeyDown
    }]);

    // bind onBlur event listener to fix popup not close when clicking on iframe outside
    var unbindBlur = noop;
    unbindBlur = bind(window, {
      type: 'blur',
      listener: function onBlur(e) {
        if (isLayerDisabled() || !(document.activeElement instanceof HTMLIFrameElement)) {
          return;
        }
        var wrapper = document.activeElement.closest('[data-ds--level]');
        if (!wrapper || currentLevel > Number(wrapper.getAttribute('data-ds--level'))) {
          closePopup(e);
        }
      }
    });
    return function () {
      unbind();
      unbindBlur();
    };
  }, [isOpen, onClose, popupRef, triggerRef, capture, isLayerDisabled, shouldCloseOnTab, currentLevel]);
};