import React from "react";
import { Content, Layout } from "../components/layout";
import { ProductRange } from "../components/ProductRange";
import { useLoaderData } from "react-router-dom";
import { useSEO } from "../hooks/seo";
import { SEO } from "../components/SEO";
import { ProductRange as ProductRangeType } from "./types";

export function ProductRangePage() {
  const { range } = useLoaderData() as { range: ProductRangeType };
  useSEO({ title: `${range.name}` });

  return (
    <Layout
      breadCrumbs={[
        { href: "/products", name: "Products" },
        { href: `/products/${range.code}`, name: range.name },
      ]}
    >
      <SEO
        title={range.name}
        description={`${range.name} - ${range.description}`}
      />
      <Content>
        <ProductRange range={range} />
      </Content>
    </Layout>
  );
}
