import React, { useCallback, useMemo, useEffect } from "react";
import { ImageWithPlaceholder } from "./ImageWithPlaceholder";
import styled from "styled-components";
import { colours } from "../theme/theme";
import { ImageType, Product, image_types } from "../products/types";
import { productThumbnail } from "../products/utils";
import { Logo } from "./logo";
import { zIndex } from "../zIndex";

type Props = {
  onClose: () => void;
  selectedImage: ImageType;
  product: Product;
};

export function ExpandedImageModal({ selectedImage, onClose, product }: Props) {
  const [mainImageKey, setMainImageKey] =
    React.useState<ImageType>(selectedImage);

  const largeImage = useMemo(
    () => productThumbnail(product, mainImageKey, "2000"),
    [product, mainImageKey]
  );

  const handleNavigateLeft = useCallback(
    (e: React.MouseEvent | KeyboardEvent) => {
      e.stopPropagation();

      const current_index = image_types.indexOf(mainImageKey);
      const next_index =
        current_index === 0 ? image_types.length - 1 : current_index - 1;
      setMainImageKey(image_types[next_index]);
    },
    [mainImageKey]
  );

  const handleNavigateRight = useCallback(
    (e: React.MouseEvent | KeyboardEvent) => {
      e.stopPropagation();

      const current_index = image_types.indexOf(mainImageKey);
      const next_index =
        current_index === image_types.length - 1 ? 0 : current_index + 1;
      setMainImageKey(image_types[next_index]);
    },
    [mainImageKey]
  );

  useEffect(() => {
    // Add event listener for keydown, left & right arrow keys
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        handleNavigateLeft(e);
      } else if (e.key === "ArrowRight") {
        handleNavigateRight(e);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNavigateLeft, handleNavigateRight]);

  return (
    <ExpandedImageModalBackdrop onClick={onClose}>
      <ExpandedImageModalStyled>
        <TopLeftLogoWrapper>
          <Logo width={50} />
        </TopLeftLogoWrapper>
        <ExpandedImageModalLeftArrowWrapper onClick={handleNavigateLeft}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
        </ExpandedImageModalLeftArrowWrapper>
        <Image src={largeImage} width={"100%"} height={"100%"} />
        <ExpandedImageModalCloseIconWrapper onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
          </svg>
        </ExpandedImageModalCloseIconWrapper>
        <ExpandedImageModalRightArrowWrapper onClick={handleNavigateRight}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
          </svg>
        </ExpandedImageModalRightArrowWrapper>
      </ExpandedImageModalStyled>
    </ExpandedImageModalBackdrop>
  );
}

const Image = styled(ImageWithPlaceholder)`
  border-radius: 5px;
  box-shadow: 0 0 7px #000;
  object-fit: cover;
`;


const ExpandedImageModalStyled = styled.div`
  position: fixed;
  width: 80%;
  height: 80%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // Center
  transform: translate(10%, 10%);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExpandedImageModalBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${zIndex.modal};
`;

const ExpandedImageModalCloseIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  svg {
    fill: white;
    width: 100%;
    height: 100%;
  }
`;

const ExpandedImageModalRightArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  svg {
    fill: white;
    width: 100%;
    height: 100%;
  }
`;

const ExpandedImageModalLeftArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  svg {
    fill: white;
    width: 100%;
    height: 100%;
  }
`;

const TopLeftLogoWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;
