import React from "react";
import { Helmet } from "react-helmet";

export function SEOTitle({ title }: { title?: string }) {
  return title
    ? [
        <meta property="og:title" content={title} />,
        <meta name="twitter:title" content={title} />,
        <title>Dukes Group | {title}</title>,
      ].map((el) => el)
    : null;
}

export function SEODescription({ description }: { description?: string }) {
  return description
    ? [
        <meta name="description" content={description} />,
        <meta property="og:description" content={description} />,
        <meta name="twitter:description" content={description} />,
      ].map((el) => el)
    : null;
}

export function SEOImage({ image }: { image?: string }) {
  return image
    ? [
        <meta property="og:image" content={image} />,
        <meta name="twitter:image" content={image} />,
      ].map((el) => el)
    : null;
}

export function SEOUrl({ url }: { url?: string }) {
  return url
    ? [
        <meta property="og:url" content={url} />,
        <meta name="twitter:url" content={url} />,
      ].map((el) => el)
    : null;
}


export function SEO({
  title,
  description,
  image,
  url,
  keywords,
  children,
}: {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  keywords?: string;
  children?: React.ReactNode;
}) {
  return (
    <Helmet>
      {SEOTitle({ title })}
      {SEODescription({ description })}
      {SEOImage({ image })}
      {SEOUrl({ url })}
      {keywords && <meta name="keywords" content={keywords} />
      {children}
    </Helmet>
  );
}
