/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
// AUTOGENERATED. DO NOT EDIT.
/**
 * Security sensitive attribute names that should not be set through
 * `setAttribute` or similar functions.
 */
export const SECURITY_SENSITIVE_ATTRIBUTES = [
    'src',
    'srcdoc',
    'codebase',
    'data',
    'href',
    'rel',
    'action',
    'formaction',
    'sandbox',
    'cite',
    'poster',
    'icon',
];
