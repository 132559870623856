import styled from "styled-components";
import React, { useMemo, useState } from "react";
import { colours } from "../theme/theme";
import { useLocation, useResolvedPath } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "../hooks/navigation";

export function Menu() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  if (isMobile) {
    return <MobileMenu />;
  }

  return (
    <MenuWrapper>
      <MenuItem title="Home" to="/" />
      <MenuItem to="/products" title="Products" />
      <MenuItem to="/contact" title="Contact" />
      <MenuItem to="/retailers" title="Retailers" />
    </MenuWrapper>
  );
}
function MenuItem({ title, to }: { title: string; to: string }) {
  let path = useResolvedPath(to, { relative: false });
  let location = useLocation();
  const navigation = useNavigate();
  const endSlashPosition =
    path.pathname !== "/" && path.pathname.endsWith("/")
      ? path.pathname.length - 1
      : path.pathname.length;

  const isActive = useMemo(
    () =>
      location.pathname === path.pathname ||
      (location.pathname.startsWith(path.pathname) &&
        location.pathname.charAt(endSlashPosition) === "/"),
    [location, path.pathname, endSlashPosition]
  );

  const onClick = () => {
    navigation(path.pathname);
  };

  return (
    <StyledMenuItem isActive={isActive} onClick={onClick}>
      {title}
    </StyledMenuItem>
  );
}

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledMenuItem = styled.div<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? colours.lightGold : colours.gold)};
  font-size: 1rem;
  margin: 0 10px;
  cursor: pointer;

  &:hover {
    color: ${colours.lightGold};
  }
`;

function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <MobileHamburger onClick={() => setIsOpen(!isOpen)}>
        {!isOpen ? (
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12h18M3 6h18M3 18h18"
              stroke={colours.gold}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke={colours.gold}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke={colours.gold}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </MobileHamburger>
      <MobileMenuWrapper isOpen={isOpen}>
        <MenuItem title="Home" to="/" />
        <MenuItem to="/products" title="Products" />
        <MenuItem to="/contact" title="Contact" />
        <MenuItem to="/retailers" title="Retailers" />
      </MobileMenuWrapper>
    </>
  );
}

const MobileMenuWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #343434;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 100;
  padding: 20px;
  transition: transform 0.3s;
  transform: ${(props) =>
    props.isOpen ? "translateY(0)" : "translateY(-100%)"};
`;

const MobileHamburger = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 101;

  @media (max-width: 768px) {
    display: block;
  }
`;
