import React, { useCallback } from "react";
import { Product, ProductRange } from "../products/types";
import { Heading } from "./Heading";
import styled from "styled-components";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import { useMediaQuery } from "react-responsive";
import { WrappedWithProductCard } from "./Cards/ProductCard";
import { useNavigate } from "../hooks/navigation";
import { productThumbnail } from "../products/utils";
import { GalleryImage } from "./GalleryImage";

export function ProductRange({ range }: { range: ProductRange }) {
  const isMobile = useMediaQuery({ maxWidth: 700 });

  const Gallery = isMobile
    ? ProductRangeProductImageGalleryCarousel
    : ProductRangeProductImageGallery;

  const href = `/products/${range.code}`;

  const nav = useNavigate();

  const onClick = useCallback(() => {
    nav(href);
  }, [nav, range.code]);
  return (
    <Wrapper>
      <Gallery range={range} />
      <ContentWrapper href={href} onClick={onClick}>
        <Heading level="h2">{range.name}</Heading>
        <Description>{range.description}</Description>
      </ContentWrapper>
    </Wrapper>
  );
}

function ProductRangeProductImageGallery({ range }: { range: ProductRange }) {
  return (
    <ImageGalleryWrapper>
      <ImageGallery>
        {range.products.map((product) => (
          <ProductRangeProductImageGalleryImage
            product={product}
            range={range}
            key={`image-gallery_${product.code}_${range.code}`}
          />
        ))}
      </ImageGallery>
    </ImageGalleryWrapper>
  );
}

function ProductRangeProductImageGalleryImage({
  product,
  range,
}: {
  product: Product;
  range: ProductRange;
}) {
  const navigate = useNavigate();
  return (
    <WrappedWithProductCard
      product={product}
      range={range}
      key={`image-gallery_${product.code}_${range.code}`}
    >
      {(triggerProps) => (
        <GalleryImageContainer
          {...triggerProps}
          onClick={() =>
            navigate(`/products/${range.code}/${product.colourCode}`)
          }
        >
          <GalleryImage key={product.code} src={productThumbnail(product)} height={'100%'} width={'100%'} />
          <ImageTitle>{product.colour}</ImageTitle>
          {/* <ImageTitleOverlay>{product.colour}</ImageTitleOverlay> */}
        </GalleryImageContainer>
      )}
    </WrappedWithProductCard>
  );
}

function ProductRangeProductImageGalleryCarousel({
  range,
}: {
  range: ProductRange;
}) {
  const navigate = useNavigate();

  return (
    <Carousel showThumbs={false} showStatus={false} infiniteLoop>
      {range.products.map((product) => (
        <CarouselImageContainer
          key={`image-gallery_${product.code}_${range.code}`}
          onClick={() =>
            navigate(`/products/${range.code}/${product.colourCode}`)
          }
        >
          <CarouselImage src={productThumbnail(product, undefined, '600')} />
          <ImageTitleOverlay>{product.colour}</ImageTitleOverlay>
        </CarouselImageContainer>
      ))}
    </Carousel>
  );
}

const ContentWrapper = styled.a`
  padding: 1em;
`;

const Description = styled.p`
  font-size: 1em;
  padding: 0.9em;
  max-width: 650px;
  margin: 0 auto;
  line-height: 1.5em;
`;

const Wrapper = styled.div`
  padding: 1em;
  //   border: 1px solid #ccc;
  border-radius: 5px;
  margin: 1em;
`;

const ImageGalleryWrapper = styled.div`
  width: 100%;
`;

const CarouselImageContainer = styled.div`
  border-radius: 5px;
  height: 100%;
`;
const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const ImageTitleOverlay = styled.div`
  position: absolute;
  bottom: 0px;
  height: 60px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 0.5em;

  font-family: "Roboto", sans-serif;
  font-style: italic;

  opacity: 1;
`;

const GalleryImageContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 5px;
  min-height: 240px;

  box-shadow: 0 0 3px #0a0a0a;
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  // height: 200px;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
`;

const ImageGallery = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
`;

const ImageTitle = styled.div`
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
