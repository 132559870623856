import { Product } from "../../types";
import { getProductImageMap as getProductImageMapBase } from "../../utils";

const getProductImageMap = (productKey: string) => getProductImageMapBase(productKey, "signature");

export const signatureProducts: Product[] = [
  {
    code: "260-18-1",
    colour: "Cloud",
    colourCode: "Cloud",
    order: 1,
    images: getProductImageMap("260-18-1_Cloud"),
  },
  {
    code: "260-18-2",
    colour: "Sesame",
    colourCode: "Sesame",
    order: 2,
    images: getProductImageMap("260-18-2_Sesame"),
  },
  {
    code: "260-18-3",
    colour: "Tan",
    colourCode: "Tan",
    order: 3,
    images: getProductImageMap("260-18-3_Tan"),
  },
  {
    code: "260-18-5",
    colour: "Parana",
    colourCode: "Parana",
    order: 4,
    images: getProductImageMap("260-18-5_Parana"),
  },
  {
    code: "260-18-7",
    colour: "Hazel",
    colourCode: "Hazel",
    order: 50,
    images: getProductImageMap("260-18-7_Hazel"),
  },
].sort((a, b) => a.order - b.order);
