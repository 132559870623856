/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
import { unwrapUrlOrSanitize } from '../../builders/url_builders.js';
import { isResourceUrl, unwrapResourceUrl, } from '../../internals/resource_url_impl.js';
const SAFE_URL_REL_VALUES = [
    'alternate',
    'author',
    'bookmark',
    'canonical',
    'cite',
    'help',
    'icon',
    'license',
    'modulepreload',
    'next',
    'prefetch',
    'dns-prefetch',
    'prerender',
    'preconnect',
    'preload',
    'prev',
    'search',
    'subresource',
];
export function setHrefAndRel(link, url, rel) {
    if (isResourceUrl(url)) {
        setHrefAndRelWithTrustedResourceUrl(link, url, rel);
        return;
    }
    else {
        if (SAFE_URL_REL_VALUES.indexOf(rel) === -1) {
            throw new Error(`TrustedResourceUrl href attribute required with rel="${rel}"`);
        }
        const sanitizedUrl = unwrapUrlOrSanitize(url);
        if (sanitizedUrl === undefined) {
            return;
        }
        link.href = sanitizedUrl;
    }
    link.rel = rel;
}
/**
 * Safely sets a link element's "href" property using a TrustedResourceUrl and
 * an arbitrary "rel" value. It is recommended to use this method when the url
 * is always a TrustedResourceUrl, since the resulting binary size will be
 * smaller.
 */
export function setHrefAndRelWithTrustedResourceUrl(link, url, rel) {
    link.href = unwrapResourceUrl(url).toString();
    link.rel = rel;
}
