import React, { useCallback, useEffect, useState } from "react";
import { StyledInput } from "../components/input";
import { getCookieValue } from "./cookies";
import { salesInClient } from "./salesinClient";
import {
  LoadingButtonWithSpinner,
  StyledLoginButton,
  InfoMessageContainer,
  ErrorMesssageContainer,
} from "../components";

export function ForgotPasswordForm() {
  const [username, setUsername] = useState("");

  const [sending, setSending] = useState(false);

  const [sent, setSent] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onReset = useCallback(async () => {
    setSending(true);
    const salesClientResponse = await salesInClient.sendForgotPasswordEmail(
      username
    );

    if (!salesClientResponse.sent && salesClientResponse.errorMessage) {
      setError(salesClientResponse.errorMessage);
      setSending(false);
      return;
    }

    if (salesClientResponse.sent) {
      setSending(false);
      setSent(true);
      return;
    }

    setSending(false);
  }, [username]);

  useEffect(() => {
    const storedUsername = getCookieValue("JobSync_Login", "username");
    if (typeof storedUsername === "string") {
      setUsername(storedUsername);
    }
  }, []);

  const Button = sending ? LoadingButtonWithSpinner : StyledLoginButton;

  return (
    <>
      <StyledInput
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={sending}
      />

      <InfoMessageContainer>
        {sending ? "Sending reset email..." : ""}
        {sent ? "Reset email sent!" : ""}
      </InfoMessageContainer>
      {error && (
        <ErrorMesssageContainer>{`⚠️ ${error}`}</ErrorMesssageContainer>
      )}
      {!sent && (
        <Button onClick={onReset} disabled={!username || sending}>
          {sending ? "" : "Reset password"}
        </Button>
      )}
    </>
  );
}
