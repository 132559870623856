import { useEffect } from "react";

type SEOProps = {
  title?: string;
};

const TITLE_PREFIX = "Dukes Group | ";

export function useSEO({ title }: SEOProps) {
  // useEffect(() => {
  //   if (title) {
  //     document.title = `${TITLE_PREFIX}${title}`;
  //   }
  // }, [title]);
}
