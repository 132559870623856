import EmailJS from "@emailjs/browser";
import { logEvent } from "../analytics/init";

export type ContactUsEmail = {
  reply_to: string;
  first_name: string;
  last_name: string;
  message: string;
  phone: string;
};

class EmailClient {
  constructor() {
    EmailJS.init({
      publicKey: "Sv6IVcdwHQOR8CbBN",
      limitRate: {
        // Set the limit rate for the application
        id: "app",
        // Allow 1 request per 10s
        throttle: 10000,
      },
    });
  }

  async sendContactUsEmail(params: ContactUsEmail) {
    try {
      await EmailJS.send("mailgun", "contact_us", params);
      logEvent("contact_us_email_sent");
    } catch (error) {
      // console.error("Failed to send email:", error);
    }
  }
}

export const emailClient = new EmailClient();
