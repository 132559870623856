import React, { useMemo } from "react";
import { ImageType, Product } from "../products/types";
import { ImageWithPlaceholder } from "./ImageWithPlaceholder";
import styled from "styled-components";

type ProductImageGalleryProps = {
  product: Product;
  onClick?: (image: ImageType) => void;
};
export function ProductImageGallery({
  product: { images },
  onClick,
}: ProductImageGalleryProps) {
  const imageArray = useMemo(() => Array.from(images.values()), [images]);
  return (
    <Wrapper>
      {images &&
        imageArray.map((image) => {
          return (
            <ProductImageGalleryImageWrapper
              key={image.imageType}
              onClick={() => onClick?.(image.imageType)}
            >
              <ProductImageGalleryImage image={image.thumbnails.get("300")} />
            </ProductImageGalleryImageWrapper>
          );
        })}
    </Wrapper>
  );
}

const SIZE = "150px";

function ProductImageGalleryImage({ image }: { image: string | undefined }) {
  if (!image) {
    return null;
  }
  return <StyledImage src={image} alt="" width={SIZE} height={SIZE} />;
}

const ProductImageGalleryImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const StyledImage = styled(ImageWithPlaceholder)`
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  object-fit: cover;
  margin-bottom: 6px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;
