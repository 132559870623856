import React from "react";
import { Content, Layout } from "../components/layout";
import { PRODUCT_RANGES } from "./store/state";
import { ProductRange } from "../components/ProductRange";
import { SEO } from "../components/SEO";

const description = `Discover Dukes Group's premium engineered oak flooring on our Products page. Explore the "Signature" range with grand 10.25" wide planks, featuring elegant, durable designs. The "Legacy" range offers 7.5" planks with warm, earthy tones and prime grade quality, perfect for timeless residential or commercial spaces. The "Inspire" range, at 9.5", combines sophistication with simplicity, showcasing unique grains and environmentally friendly materials. Elevate your interiors with our meticulously crafted, high-quality flooring options that blend style, durability, and natural beauty.`;

export function ProductsPage() {
  // useSEO({ title: `Our Range` });

  return (
    <Layout breadCrumbs={[{ href: "/products", name: "Products" }]}>
      <SEO
        title="Our Range"
        description={description}
        url="https://dukes-group.com/products"
      />

      <Content>
        {PRODUCT_RANGES.map((productRange) => (
          <ProductRange key={productRange.code} range={productRange} />
        ))}
      </Content>
    </Layout>
  );
}
