import React, { useMemo } from "react";
import { ImageType, Product } from "../products/types";
import { ImageWithPlaceholder } from "./ImageWithPlaceholder";
import styled from "styled-components";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import { productThumbnail } from "../products/utils";

export function ImageCarousel({ product }: { product: Product }) {
  const imageArray = useMemo(
    () => Array.from(product.images.values()),
    [product.images]
  );

  return (
    <Carousel showThumbs={true} showStatus={false} infiniteLoop>
      {imageArray.map((image) => (
        <CarouselImageContainer key={`image-gallery_${image.imageType}`}>
          <CarouselImage
            src={productThumbnail(product, image.imageType, "600")}
            width="100%"
            height="100%"
          />
          {/* <ImageTitleOverlay>{product.colour}</ImageTitleOverlay> */}
        </CarouselImageContainer>
      ))}
    </Carousel>
  );
}


const CarouselImageContainer = styled.div`
  border-radius: 5px;
  height: 100%;
`;
const CarouselImage = styled(ImageWithPlaceholder)`
  object-fit: cover;
  border-radius: 5px;
`;
const ImageTitleOverlay = styled.div`
  position: absolute;
  bottom: 0px;
  height: 60px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 0.5em;

  font-family: "Roboto", sans-serif;
  font-style: italic;

  opacity: 1;
`;