import { Product } from "../../types";
import { getProductImageMap as getProductImageMapBase  } from "../../utils";

const getProductImageMap = (productKey: string) => getProductImageMapBase(productKey, "legacy");

export const legacyProducts: Product[] = [
  {
    code: "190-15-1",
    colour: "Almond",
    colourCode: "Almond",
    order: 1,
    images: getProductImageMap("190-15-1_Almond"),

  },
  {
    code: "190-15-2",
    colour: "Wheat",
    colourCode: "Wheat",
    order: 2,
    images: getProductImageMap("190-15-2_Wheat"),
  },
  {
    code: "190-15-3",
    colour: "Latte",
    colourCode: "Latte",
    order: 3,
    images: getProductImageMap("190-15-3_Latte"),
  },
  {
    code: "190-15-4",
    colour: "Sauvignon",
    colourCode: "Sauvignon",
    order: 4,
    images: getProductImageMap("190-15-4_Sauvignon"),
  },
  {
    code: "190-15-8",
    colour: "Aged Copper",
    colourCode: "Aged-Copper",
    order: 5,
    images: getProductImageMap("190-15-8_Aged-copper"),
  },
].sort((a, b) => a.order - b.order);
