import styled from "styled-components";
import Logo from "../assets/logo-transparent.webp";
import React from "react";
import { Menu } from "./Menu";
import { useNavigate } from "../hooks/navigation";

export function Header() {
  const nav = useNavigate();
  const toHome = () => {
    nav("/");
  };
  return (
    <StyledHeader>
      <LogoWrapper onClick={toHome}>
        <img src={Logo} alt="logo" width={120} />
      </LogoWrapper>
      <Menu />
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  background-color: #343434;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  flex-direction: column;
  gap: 20px;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;
