export const image_types = [
  "room",
  "angled-landscape",
  "angled-portrait",
  "vertical",
  "horizontal",
] as const;
export type ImageType = (typeof image_types)[number];

export const thumbnail_sizes = ["300", "600", "1000", "2000"] as const;
export type ThumbnailSize = (typeof thumbnail_sizes)[number];
export const THUMBNAIL_SIZE: ThumbnailSize = "300";

export type ProductRangeKey = "legacy" | "signature" | "inspire";

export type Images = Map<ImageType, Image>;
export type Thumbnails = Map<ThumbnailSize, string>;

export type Image = {
  image: string;
  imageType: ImageType;
  thumbnails: Thumbnails;
};

export type Product = {
  code: string;
  colour: string;
  colourCode: string;
  order: number;
  images: Images;
};

type ProductRangeMetadataKeys =
  | "wear_layer"
  | "grade"
  | "core"
  | "size"
  | "surface"
  | "installation"
  | "finish"
  | "carton";

type Metadata = {
  label: string;
  value: string;
  order: number;
};

type ProductRangeMetadata = Map<ProductRangeMetadataKeys, Metadata>;

export type ProductRange = {
  name: string;
  description: string;
  code: string;
  order: number;
  metadata: ProductRangeMetadata;
  products: Product[];
  highlightProductCode: string;
};
