import {
  ImageType,
  Images,
  Image,
  Product,
  THUMBNAIL_SIZE,
  ThumbnailSize,
  image_types,
  ProductRangeKey,
  ProductRange,
  thumbnail_sizes,
  Thumbnails,
} from "./types";

const PRODUCT_THUMBNAIL_TYPE = "angled-portrait";
const PRODUCT_THUMBNAIL_SIZE = "300";

export const productThumbnail = (
  p: Product,
  type: ImageType = PRODUCT_THUMBNAIL_TYPE,
  size: ThumbnailSize = PRODUCT_THUMBNAIL_SIZE
) => {
  const image = p.images.get(type);
  if (!image || !image.thumbnails.has(size)) {
    return undefined;
  }
  const thumbnail = image.thumbnails.get(size);
  if (!thumbnail) {
    return image.image;
  }
  return thumbnail;
};

export const productRangeThumbnail = (
  range: ProductRange,
  type: ImageType = PRODUCT_THUMBNAIL_TYPE,
  size: ThumbnailSize = PRODUCT_THUMBNAIL_SIZE
) => {
  if (!range.products || range.products.length === 0) {
    return "";
  }

  const highlightProduct = range.products.find(
    (p) => p.code === range.highlightProductCode
  );
  return productThumbnail(highlightProduct ?? range.products[0], type, size);
};

const IMAGE_BASE = "https://dukes-group-images.web.app/images";

const imageTypeToImageTypeFileName = (type: ImageType): string => {
  switch (type) {
    case "room":
      return "Rooms";
    default:
      return type;
  }
}

const getImageWithThumbnails = (
  productCode: string,
  imageType: ImageType,
  productRange: ProductRangeKey
): Image => {
  // key will be e.g. 240-15-6_Storm
  const key = productCode.split("_")[0];
  
  // const image = require(path.join(__dirname, `${productCode}/${key}-${imageType}.jpg`)).default;
  // const thumbnail = require(path.join(__dirname,`${productCode}/thumbnails/${key}-${imageType}.300.jpg`)).default;
  const image = `${IMAGE_BASE}/${productRange}/${productCode}/${key}-${imageTypeToImageTypeFileName(imageType)}.jpg`;

  const thumbnails: Thumbnails = thumbnail_sizes.reduce((acc, size) => {
    acc.set(
      size,
      `${IMAGE_BASE}/${productRange}/${productCode}/thumbnails/${key}-${imageTypeToImageTypeFileName(imageType)}.${size}.jpg`
    );
    return acc;
  }, new Map());

  return {
    image,
    imageType,
    thumbnails: thumbnails,
  };
};

export const getProductImageMap = (
  productKey: string,
  productRange: ProductRangeKey
): Images => {
  return new Map(
    image_types.map((imageType) => {
      return [
        imageType,
        getImageWithThumbnails(productKey, imageType, productRange),
      ];
    })
  );
};
