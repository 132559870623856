import React, { forwardRef } from "react";
import { Product, ProductRange } from "../../products/types";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { colours } from "../../theme/theme";
import { Logo } from "../logo";
import Popup, {
  PopupComponentProps,
  PopupProps,
  TriggerProps,
} from "@atlaskit/popup";
import {
  Wrapper,
  WrapperLogo,
  Title,
  MetadataRow,
  MetadataLabel,
  MetadataRowUnderline,
  MetadataValue,
  PopupCardWrapper,
} from "./styled";

type ProductCardProps = {
  product: Product;
  range: ProductRange;
};

export function ProductCard({ product, range }: ProductCardProps) {
  return (
    <Wrapper>
      <WrapperLogo>
        <Logo width={50} />
      </WrapperLogo>
      <Title>{range.name}</Title>
      <MetadataRow key={`${range.code}_color`}>
        <MetadataLabel>Color</MetadataLabel>
        <MetadataRowUnderline />
        <MetadataValue>{product.colour}</MetadataValue>
      </MetadataRow>
      {Array.from(range.metadata)
        .sort(([, a], [, b]) => a.order - b.order)
        .map(([, { value, label }]) => (
          <MetadataRow key={`${range.code}_${label}`}>
            <MetadataLabel>{label}</MetadataLabel>
            <MetadataRowUnderline />
            <MetadataValue>{value}</MetadataValue>
          </MetadataRow>
        ))}
      <MetadataRow key={`${range.code}_code`}>
        <MetadataLabel>Code</MetadataLabel>
        <MetadataRowUnderline />
        <MetadataValue>{product.code}</MetadataValue>
      </MetadataRow>
    </Wrapper>
  );
}

type DGTriggerProps = {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
  ({ children, "data-testid": testId, ...props }, ref) => (
    <div {...props} ref={ref}>
      {children}
    </div>
  )
);

export function WrappedWithProductCard({
  children,
  ...props
}: ProductCardProps & {
  children: (props: TriggerProps & DGTriggerProps) => React.ReactNode;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const onHover = () => {
    // delay the open
    timeout.current = setTimeout(() => {
      setIsPopoverOpen(true);
    }, 300);
  };

  const onLeave = () => {
    // cancel the open
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setIsPopoverOpen(false);
  };

  return (
    <Popup
      autoFocus={false}
      isOpen={isPopoverOpen}
      onClose={() => setIsPopoverOpen(false)}
      content={() => (
        <PopupCardWrapper>
          <ProductCard {...props} />
        </PopupCardWrapper>
      )}
      popupComponent={CustomPopupContainer}
      trigger={(triggerProps) =>
        children({
          ...triggerProps,
          onMouseEnter: onHover,
          onMouseLeave: onLeave,
        })
      }
    />
  );
}

export function WrappedWithProductCardPopover({
  children,
  ...props
}: ProductCardProps & {
  children: (triggerProps: TriggerProps) => JSX.Element;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["left", "right"]}
      content={({ position, childRect, popoverRect }) => {
        console.log({ position, childRect, popoverRect });
        return (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={colours.gold}
            arrowSize={10}
            arrowStyle={{ opacity: 0.3 }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <ProductCard {...props} />
          </ArrowContainer>
        );
      }}
    >
      {children({
        onMouseEnter: () => setIsPopoverOpen(true),
        onMouseLeave: () => setIsPopoverOpen(true),
      })}
    </Popover>
  );
}
