import React, { useCallback, useState } from "react";
import { FlyingTrees } from "../components/FlyingTrees";
import { Logo } from "../components/logo";
import { Layout } from "../components/layout";
import {
  StyledLoginContainer,
  StyledHeading,
  ForgotPasswordContainer,
} from "../components";
import { LoginForm } from "./LoginForm";
import { ForgotPasswordForm } from "./ForgotPassword";
import { zIndex } from "../zIndex";
import styled from "styled-components";
import { HomeIcon } from "../components/HomeIcon";
import { config } from "./config";
import { useSEO } from "../hooks/seo";
import { useNavigate } from "../hooks/navigation";
import { SEO } from "../components/SEO";

export function RetailersStandalonePage() {
  useSEO({
    title: "Retailers",
  });
  const nav = useNavigate();
  const backToMainSite = () => {
    if (config.IS_STANDALONE_RETAIL) {
      // dukesgroup.us
      window.location.href = "https://dukesgroup.us";
    } else {
      nav("/");
    }
  };

  return (
    <Layout hideHeader hideFooter>
      <SEO title="Retailers" />
      <FlyingTrees>
        <FloatingHomeIconButton onClick={backToMainSite}>
          <HomeIcon />
        </FloatingHomeIconButton>
        <LoginContainer />
      </FlyingTrees>
    </Layout>
  );
}

export function RetailersPage() {
  const nav = useNavigate();
  const backToMainSite = () => {
    if (config.IS_STANDALONE_RETAIL) {
      // dukesgroup.us
      window.location.href = "https://dukesgroup.us";
    } else {
      nav("/");
    }
  };

  return (
    <Layout>
      <FlyingTrees>
        <FloatingHomeIconButton onClick={backToMainSite}>
          <HomeIcon />
        </FloatingHomeIconButton>
        <LoginContainer />
      </FlyingTrees>
    </Layout>
  );
}

function LoginContainer() {
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

  const onForgotPassword = useCallback(() => {
    // open url in new tab:
    window.open("https://b2b.salesin.com/Account", "_blank");
  }, []);

  return (
    <StyledLoginContainer>
      <Logo width={250} />
      <StyledHeading>Retailers</StyledHeading>
      {forgotPasswordMode ? <ForgotPasswordForm /> : <LoginForm />}
      <ForgotPasswordContainer onClick={onForgotPassword}>
        {forgotPasswordMode ? "Back to login" : "Forgot password?"}
      </ForgotPasswordContainer>
    </StyledLoginContainer>
  );
}

const FloatingHomeIconButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #c69c6c;
  color: white;
  border: none;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  padding: 0.5rem;
  cursor: pointer;
  z-index: ${zIndex.homeButton};
`;
