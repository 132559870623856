import React from "react";
import { LoadingButtonWithSpinner, StyledLoginButton } from "./index";

export function Button({
  isLoading,
  disabled,
  children,
  ...buttonProps
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { isLoading: boolean; disabled?: boolean }) {
  const Button = isLoading ? LoadingButtonWithSpinner : StyledLoginButton;

  const title = isLoading ? " " : children;

  return <Button disabled={disabled} {...buttonProps}>{title}</Button>;
}
