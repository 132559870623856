import React, { forwardRef } from "react";
import { ProductRange } from "../../products/types";
import { Logo } from "../logo";
import Popup, {
  PopupComponentProps,
  TriggerProps,
} from "@atlaskit/popup";
import {
  Wrapper,
  WrapperLogo,
  Title,
  MetadataRow,
  MetadataLabel,
  MetadataRowUnderline,
  MetadataValue,
  PopupCardWrapper,
} from "./styled";

type RangeCardProps = {
  range: ProductRange;
};

export function RangeCard({ range }: RangeCardProps) {
  return (
    <Wrapper>
      <WrapperLogo>
        <Logo width={50} />
      </WrapperLogo>
      <Title>{range.name}</Title>

      {Array.from(range.metadata).map(([, { value, label }]) => (
        <MetadataRow key={`${range.code}_${label}`}>
          <MetadataLabel>{label}</MetadataLabel>
          <MetadataRowUnderline />
          <MetadataValue>{value}</MetadataValue>
        </MetadataRow>
      ))}
    </Wrapper>
  );
}

type DGTriggerProps = {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
  ({ children, "data-testid": testId, ...props }, ref) => (
    <div {...props} ref={ref}>
      {children}
    </div>
  )
);

export function WrappedWithRangeCard({
  children,
  ...props
}: RangeCardProps & {
  children: (props: TriggerProps & DGTriggerProps) => React.ReactNode;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  return (
    <Popup
      autoFocus={false}
      isOpen={isPopoverOpen}
      onClose={() => setIsPopoverOpen(false)}
      content={() => (
        <PopupCardWrapper>
          <RangeCard {...props} />
        </PopupCardWrapper>
      )}
      popupComponent={CustomPopupContainer}
      trigger={(triggerProps) =>
        children({
          ...triggerProps,
          onMouseEnter: () => setIsPopoverOpen(true),
          onMouseLeave: () => setIsPopoverOpen(false),
        })
      }
    />
  );
}
