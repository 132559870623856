import React from "react";
import styled from "styled-components";

export const StyledInput = styled.input<{
  disabled?: boolean;
  hasError?: boolean;
}>`
  padding: 0.5rem;
  border: none;
  ${(props) => props.hasError && "border: 2px solid red;"}
  border-radius: 5px;
  margin: 0.5rem;
  width: 100%;
  height: 1.4rem;
  background-color: ${(props) => (props.disabled ? "#9e9e9e" : "#fff")};
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 50px;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
`;
