import React, { useEffect, useState } from "react";
import { ImagePlaceholderCard } from "./Cards/ImagePlaceholderCard";
import styled, { keyframes } from "styled-components";

export const ImageWithPlaceholder = ({
  src: imagePath,
  alt,
  height,
  width,
  ...rest
}: React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  height: number | string;
  width: number | string;
}) => {
  const [loadedImage, setLoadedImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!imagePath) return;
    // Preload the main image
    setLoadedImage(null);
    const img = new Image();
    img.src = imagePath;
    img.onload = () => {
      setLoadedImage(imagePath);
    };
    img.onerror = () => {
      setError("Error loading image");
    };
  }, [imagePath]);

  if (error) {
    return null;
  }

  return loadedImage ? (
    <FadeInImage
      src={loadedImage}
      alt={alt}
      {...rest}
      height={height}
      width={width}
    />
  ) : (
    <ImagePlaceholderCard isLoading={!error} height={height} width={width} />
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeInImage = styled.img`
  animation: ${fadeIn} 0.5s ease-in-out;
`;
