/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
import { unwrapResourceUrl, } from '../../internals/resource_url_impl.js';
/**
 * Sets the Href attribute from the given TrustedResourceUrl.
 */
export function setHref(baseEl, url) {
    baseEl.href = unwrapResourceUrl(url);
}
