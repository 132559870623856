import styled from "styled-components";
import { colours } from "../../theme/theme";

export const Wrapper = styled.div`
  background-color: #272727;
  border-radius: 6px;

  padding: 20px;

  box-shadow: 0 0 10px #c69c6c36;

  min-width: 300px;

  height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const WrapperLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  font-family: Roboto, sans-serif;
  color: ${colours.gold};
  padding-bottom: 20px;
  border-bottom: 1px solid ${colours.gold};
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
`;

export const MetadataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: flex-end;
`;

export const MetadataLabel = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: ${colours.gold};
`;

export const MetadataValue = styled.span`
  // underline to start of label
  border-bottom: 1px solid ${colours.gold};
  padding-bottom: 2px;
  font-weight: 600;
  padding-left: 10px;
  text-align: right;
`;

export const MetadataRowUnderline = styled.div`
  flex: 1;
  padding-bottom: 2px;
  border-bottom: 1px solid ${colours.gold};
`;

export const PopupCardWrapper = styled.div`
  max-width: 300px;
`;
