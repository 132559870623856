import { ProductRange } from "../types";
import { inspireProducts } from "./inspire/inspire";
import { legacyProducts } from "./legacy/legacy";

import { signatureProducts } from "./signature/signature";

export type ProductStoreState = {
  ranges: ProductRange[];
};

const signatureRange: ProductRange = {
  name: 'Signature - 10.25"',
  description:
    "Premium wide plank engineered oak. Providing grand elegance, sophistication years of history. Grand 8ft long and 10.25” width planks. Our premium collection of high-quality, modern engineered timber flooring, the “Signature” range is meticulously crafted to elevate any space with style and durability. Our range features a fusion of innovative engineering, durability, natural beauty, and rich timeless colours.",
  code: "signature",
  order: 1,
  metadata: new Map([
    ["wear_layer", { value: "4mm", label: "Wear Layer", order: 3 }],
    ["grade", { label: "Grade", value: "Prime / AB", order: 4 }],
    ["core", { label: "Core", value: "Baltic Birch Ply", order: 5 }],
    ["size", { label: "Size", value: '95.28" x 10.25" x 3/4"', order: 2 }],
    ["surface", { label: "Surface", value: "Wire brushed", order: 6 }],
    [
      "installation",
      {
        label: "Installations",
        value: "Glue down, Nail/Staple with glue assists, or Floating",
        order: 70,
      },
    ],
    [
      "finish",
      { label: "Finish", value: "UV water based matte lacquer", order: 8 },
    ],
    [
      "carton",
      { label: "Sq ft per carton", value: "27.09", order: 9 },
    ]
  ]),
  highlightProductCode: '260-18-1',
  products: signatureProducts,
};

const legacyRange: ProductRange = {
  name: 'Legacy - 7.5"',
  description:
    "The perfect combination of style and substance with our “Legacy” range engineered oak timber flooring range.  Earthy warm tones providing the stable foundations to a timeless space. Prime grade with beautiful grain, a simply timeless investment that elevates any residential or commercial space with unparalleled sophistication and enduring allure. ",
  code: "legacy",
  order: 2,
  metadata: new Map([
    ["wear_layer", { value: "3mm", label: "Wear Layer", order: 3 }],
    ["grade", { label: "Grade", value: "Prime / AB", order: 4 }],
    ["core", { label: "Core", value: "Baltic Birch Ply", order: 5 }],
    ["size", { label: "Size", value: '74.8" x 7.5" x 5/8"', order: 2 }],
    ["surface", { label: "Surface", value: "Wire brushed", order: 6 }],
    [
      "installation",
      {
        label: "Installations",
        value: "Glue down, Nail/Staple with glue assists, or Floating",
        order: 70,
      },
    ],
    [
      "finish",
      { label: "Finish", value: "UV water based matte lacquer", order: 8 },
    ],
    [
      "carton",
      { label: "Sq ft per carton", value: "23.32", order: 9 },
    ]
  ]),
  products: legacyProducts,
  highlightProductCode: '190-15-3',
};

const inspireRange: ProductRange = {
  name: 'Inspire - 9.5"',
  description:
    "Where sophistication meets simplicity. The high quality production, each plank provides timeless charm, elevating any space with its timeless charm and offering a resilient floor covering. Light Character grading showing the uniqueness and naturally grown environmentally friendly nature of Engineered flooring. A harmonium blend of spaciousness and warmth featuring distinct grains and subtleness that captivates the eye.",
  code: "inspire",
  order: 3,
  metadata: new Map([
    ["wear_layer", { value: "3mm", label: "Wear Layer", order: 3 }],
    ["grade", { label: "Grade", value: "Light Character / ABC", order: 4 }],
    ["core", { label: "Core", value: "Eucalyptus Plywood", order: 5 }],
    ["size", { label: "Size", value: '86.61" x 9.5" x 5/8"', order: 2 }],
    ["surface", { label: "Surface", value: "Wire brushed", order: 6 }],
    [
      "installation",
      {
        label: "Installations",
        value: "Glue down, Nail/Staple with glue assists, or Floating",
        order: 70,
      },
    ],
    [
      "finish",
      { label: "Finish", value: "UV water based matte lacquer", order: 8 },
    ],
    [
      "carton",
      { label: "Sq ft per carton", value: "22.73", order: 9 },
    ]
  ]),
  products: inspireProducts,
  highlightProductCode: '240-15-6',
};

export const PRODUCT_RANGES = [signatureRange, legacyRange, inspireRange].sort(
  (a, b) => a.order - b.order
);

export const getRange = (code: string) =>
  PRODUCT_RANGES.find(
    (range) => range.code.toLowerCase() === code.toLowerCase()
  );

export const getProduct = (code: string, productColourCode: string) => {
  const range = getRange(code);
  return range?.products.find(
    (product) =>
      product.colourCode.toLowerCase() === productColourCode.toLowerCase()
  );
};
