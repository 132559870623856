export function setCookie(name: string, value: string, expDays: number) {
  var d = new Date();
  d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookies() {
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  var cookies: { [key: string]: string } = {};
  ca.forEach((c) => {
    var [key, value] = c.split("=");
    cookies[key.trim()] = value;
  });
  return cookies;
}

export function getCookie(name: string) {
  var cookies = getCookies();
  if (cookies[name]) {
    return cookies[name];
  }
  return null;
}

export function setCookieValue(
  name: string,
  key: string,
  value: string | boolean
) {
  var cookie = getCookie(name);
  if (cookie) {
    var cookieObj = JSON.parse(cookie);
    cookieObj[key] = value;
    setCookie(name, JSON.stringify(cookieObj), 365);
  } else {
    setCookie(
      name,
      JSON.stringify({
        [key]: value,
      }),
      365
    );
  }
}

export function getCookieValue(name: string, key: string) {
  try {
    var cookie = getCookie(name);
    if (cookie) {
      var cookieObj = JSON.parse(cookie);
      return cookieObj[key];
    }
    return null;
  } catch {
    return null;
  }
}
