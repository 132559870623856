import React from "react";
import { colours } from "../theme/theme";

type HeadingProps = {
  children: string;
  level?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

export function Heading({ children, level = "h1" }: HeadingProps) {
  const Tag = level;
  return (
    <Tag
      style={{
        // fontStyle: "italic",
        color: colours.gold,
        margin: 0,
      }}
    >
      {children}
    </Tag>
  );
}
