import React from "react";
import { Logo } from "../logo";
import styled from "styled-components";

export function ImagePlaceholderCard({
  isLoading,
  height,
  width,
}: {
  isLoading: boolean;
  height: number | string;
  width: number | string;
}) {
  return (
    <Wrapper style={{ height, width }}>
      <WrapperLogo>
        <Logo width={50} />
        {isLoading && <Spinner />}
      </WrapperLogo>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #272727;
  border-radius: 5px;

`;

const WrapperLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
`;

const Spinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
