import { useCallback } from "react";
import { useNavigate as useNavigateBase } from "react-router-dom";

export function useNavigate() {
  const nav = useNavigateBase();

  const navigate = useCallback(
    (to: string) => {
      nav(to, {
        unstable_viewTransition: true,
        unstable_flushSync: true,
      });

      //   if (document.startViewTransition) {
      //     document.startViewTransition(() => {
      //       flushSync(() => {
      //         nav(to);
      //       });
      //     });
      //   } else {
      //     nav(to);
      //   }
    },

    [nav]
  );

  return navigate;
}
