import React, { useCallback, useMemo } from "react";
import { ProductRange } from "../products/types";
import { Heading } from "./Heading";
import styled from "styled-components";
import { WrappedWithRangeCard } from "./Cards/RangeCard";
import { useNavigate } from "../hooks/navigation";
import { productRangeThumbnail } from "../products/utils";
import { ImageWithPlaceholder } from "./ImageWithPlaceholder";

export function ProductRangeTile({ range }: { range: ProductRange }) {
  const firstProductImage = useMemo(
    () => productRangeThumbnail(range, "room"),
    [range]
  );

  const nav = useNavigate();

  const href = `/products/${range.code}`;

  const onClick = useCallback(() => {
    nav(href);
  }, [nav, range.code]);
  return (
    <WrappedWithRangeCard range={range}>
      {(triggerProps) => (
        <Wrapper {...triggerProps} onClick={onClick} href={href}>
          <Image
            src={firstProductImage}
            alt={range.name}
            width={"300px"}
            height={"300px"}
          />
          <Heading level="h3">{range.name}</Heading>
        </Wrapper>
      )}
    </WrappedWithRangeCard>
  );
}

const Image = styled(ImageWithPlaceholder)`
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  object-fit: cover;
  margin-bottom: 6px;
`;

const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  cursor: pointer;

  &:hover ${Image} {
    transform: scale(1.02);
  }
`;
