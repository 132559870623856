import React, { useCallback } from "react";
import { CopyIcon } from "./CopyIcon";
import styled from "styled-components";

export function CopyableText({ children }: { children: string }) {
    const [showCopyText, setShowCopyText] = React.useState(false);
  
    const [text, setText] = React.useState(children);
  
    const copyText = useCallback(() => {
      navigator.clipboard.writeText(children);
      setText("Copied to clipboard!");
      setTimeout(() => {
        setText(children);
      }, 1000);
    }, [children]);
  
    return (
      <CopyTextContainer
        onMouseEnter={() => setShowCopyText(true)}
        onMouseLeave={() => setShowCopyText(false)}
        onClick={copyText}
      >
        <p>{text}</p>
        {showCopyText && (
          <CopyIconContainer>
            <CopyIcon />
          </CopyIconContainer>
        )}
      </CopyTextContainer>
    );
  }
  
  
  const CopyTextContainer = styled.div`
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  `;
  
  const CopyIconContainer = styled.div`
    width: 10px;
    height: 10px;
    svg {
      fill: white;
      width: 100%;
      height: 100%;
    }
  `;