import React, { useMemo } from "react";
import { Content, Layout } from "../components/layout";
import { StyledInput, StyledTextArea } from "../components/input";
import styled from "styled-components";
import { Button } from "../components/Button";
import { toast } from "react-toastify";
import { ContactUsEmail, emailClient } from "../email/emailClient";
import { useSEO } from "../hooks/seo";
import { SEO } from "../components/SEO";
import { useMediaQuery } from "react-responsive";
import { CopyableText } from "../components/CopyableText";

export function ContactUsPage() {
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const [isLoading, setIsLoading] = React.useState(false);
  const [sendSuccess, setSendSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [details, setDetailsInner] = React.useState<ContactUsEmail>({
    first_name: "",
    last_name: "",
    reply_to: "",
    message: "",
    phone: "",
  });

  const setDetails = (newDetails: ContactUsEmail) => {
    setDetailsInner(newDetails);
    if (isValid && showError) setShowError(false);
  };

  const isValid = useMemo(
    () => details.first_name && details.phone,
    [details]
  );

  const onSubmit = async () => {
    if (!isValid) {
      setShowError(true);
      toast.error("Please fill in all required fields", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }
    setShowError(false);
    setIsLoading(true);
    await emailClient.sendContactUsEmail(details);

    toast.success("Message sent!", {
      position: "bottom-center",
      autoClose: 2000,
    });

    setSendSuccess(true);

    setIsLoading(false);
  };

  return (
    <Layout>
      <SEO title="Contact Us" />
      {!sendSuccess ? (
        <Content>
          <Row>
            <StyledInput
              type="text"
              placeholder="First Name"
              value={details.first_name}
              onChange={(e) =>
                setDetails({ ...details, first_name: e.target.value })
              }
              hasError={showError && !details.first_name}
            />
            <StyledInput
              type="text"
              placeholder="Last Name"
              value={details.last_name}
              onChange={(e) =>
                setDetails({ ...details, last_name: e.target.value })
              }
            />
          </Row>
          <Row>
            <StyledInput
              type="email"
              placeholder="Email"
              value={details.reply_to}
              onChange={(e) =>
                setDetails({ ...details, reply_to: e.target.value })
              }
              hasError={showError && !details.reply_to}
            />
            <StyledInput
              type="tel"
              placeholder="Phone"
              value={details.phone}
              onChange={(e) =>
                setDetails({ ...details, phone: e.target.value })
              }
              hasError={showError && !details.phone}
            />
          </Row>
          <Row>
            <StyledTextArea
              placeholder="Message"
              value={details.message}
              onChange={(e) =>
                setDetails({ ...details, message: e.target.value })
              }
            />
          </Row>
          <Row>
            <Button
              isLoading={isLoading}
              disabled={false}
              type="submit"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Row>
        </Content>
      ) : (
        <Content>
          <p>Thank you for your message!</p>
        </Content>
      )}
      <Phone>
        {isMobile ? (
          <a href="tel:+18665125177">+1 866 5125 177</a>
        ) : (
          <CopyableText>+1 866 5125 177</CopyableText>
        )}
      </Phone>
    </Layout>
  );
}

const Row = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-inline: 18px;
  }
  p {
    margin: 0 auto;
    text-align: center;
  }
`;

const Phone = styled.p`
  margin: 0 auto;
  text-align: center;
  font-size: 0.8em;
  margin-top: 2rem;
`;
