import styled from "styled-components";
import { HIDE_ON_MOBILE } from "./constants";

export const InfoMessageContainer = styled.div`
  height: 0.8rem;
  font-size: 0.8rem;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
`;

export const ErrorMesssageContainer = styled.div`
  font-size: 0.8rem;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
`;

export const ForgotPasswordContainer = styled.div`
  font-size: 0.7rem;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-decoration: underline;
  cursor: pointer;
`;

export const StyledHeading = styled.h2`
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  font-weight: 400;
  font-style: italic;
`;

export const StyledLoginContainer = styled.div`
  background-color: #343434;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 35px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 330px;

  @media (max-width: ${HIDE_ON_MOBILE}px) {
    width: 80%;
    height: 100%;
    padding: 100px;
  }
`;

export const StyledLoginButton = styled.button<{ disabled?: boolean }>`
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  margin: 0.5rem;
  background-color: #c69c6c;
  color: white;
  cursor: pointer;
  width: 100%;
  height: 2.4rem;
`;
export const LoadingButtonWithSpinner = styled(StyledLoginButton)`
  position: relative;
  background-color: #c69c6c;
  color: white;
  cursor: not-allowed;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
